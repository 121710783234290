import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('IMOs'),
    icon: 'TicketIcon',
    href: '/imo',
  },
  {
    label: t('Staking'),
    icon: 'PoolIcon',
    href: '/staking',
  },
  {
    label: t('Your Profile'),
    icon: 'NftIcon',
    href: '/profile',
  },
  {
    label: t('Submit IMOs   '),
    icon: 'IfoIcon',
    href: 'https://forms.gle/7YmoVFSVGZh7J1HN8',
  },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Github'),
        href: 'https://github.com/gaurtoken-finance',
      },
      {
        label: t('Docs'),
        href: 'https://docs.gaurtoken.finance',
      }
    ],
  },
]

export default config
